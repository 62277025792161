/* _______________ DESKTOP HEADER ________________ */
.wrapper-header {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 3000;
  border-radius: 25px;
  height: 55px;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 20px);
  max-width: 1440px;
  margin: 0 auto;
}

.carousel-section {
  width: calc(100% - 450px);
  height: 100%;
  position: relative;
  border-right: 1px solid rgba(99, 130, 191, 0.2);
}

.fixed-width-section {
  width: 450px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .fixed-width-section {
    width: 370px;
  }
  .carousel-section {
    width: calc(100% - 370px);
  }
}

/* wrapper for all dates */
.dates {
  display: flex;
  justify-content: space-around;
  color: #535353;
  position: relative;
  width: 100%;
  padding-right: 100px;
  height: 55px;
  overflow: hidden;
}

.carousel-item {
  position: inherit;
  height: 55px;
}

.carousel-container {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.dates-child {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 15px 13px 10px;
  border-bottom: 3px solid transparent;
  border-right: 1px solid rgba(99, 130, 191, 0.1);
  height: 55px;
}

.dates-child:hover {
  border-bottom: 3px solid #63a6fd;
  cursor: pointer;
}

.dates-child svg {
  margin-right: 10px;
}

.date-selected {
  border-bottom: 3px solid #63a6fd;
}

/* wrapper for date buttons */
.arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 15px 38px 15px 38px;
  position: absolute;
  right: -100px;
  background-color: white;
  height: 100%;
}

.react-multi-carousel-list {
  overflow: visible !important;
}

.arrow-child {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.arrow-disable {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white !important;
  cursor: auto;
}

.arrow-disable:hover {
  background-color: white !important;
}

.arrow-disable svg path {
  fill: #535353;
}

.arrow-child:hover svg path {
  fill: white;
}

.gap {
  padding-right: 10px;
  padding-left: 10px;
}

.wrapper-reference-date {
  min-width: 135px;
  padding: 0 6px 0 10px;
  width: calc(50% - 16px);
}

.reference-date {
  position: relative;
  height: 36px;
  border: 1px solid rgba(211, 211, 211, 0.5);
  border-radius: 15px;
  padding-left: 15px;
  color: rgba(83, 83, 83, 0.5);
}

.wrapper-reference-date[disabled],
.reference-date[disabled],
.dates[disabled] {
  filter: grayscale(1);
  opacity: 0.7;
  pointer-events: none;
}

.dates[disabled] .carousel-container {
  opacity: 0.5;
}

.reference-date p {
  margin-top: 8px;
}

.heading-on-border {
  font-size: 10px;
  color: #535353;
  padding: 0 2px;
  background-color: #fff;
  top: -7px;
  left: 12px;
  position: absolute;
}

.search-bar-select {
  padding: 0 6px 0 0;
  width: calc(50% - 6px);
}

.location-select__indicator path,
.location-select__indicator path {
  fill: #fff !important;
}

.select-label {
  font-size: 10px;
  color: #535353;
  padding: 0 2px;
  background-color: #fff;
  top: -5px;
  left: 15px;
  position: absolute;
  z-index: 100;
}

/* hide desktop version on screens smaller than 426px */
@media screen and (max-width: 768px) {
  .wrapper-header {
    display: none;
  }
}

/* _______________ MOBILE HEADER ________________ */
.header-modal {
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 80%;
  z-index: 3000;
  transition: transform 0.5s ease-in-out;
  transform: translateY(calc(100% - 60px));
}

.header-modal.opened {
  transform: translateY(0);
}

.wrapper-header-mobile {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(15, 43, 80, 0.5);
  z-index: 3000;
  height: 60px;
  width: 100%;
  position: relative;
}

.line-mobile {
  position: absolute;
  height: 5px;
  width: 60px;
  background-color: #0f2b50;
  border-radius: 10px;
  top: 15px;
  transform: translateY(0);
  transition: transform 0.5s ease-in-out;
}

.line-mobile.opened {
  transform: translateY(20px);
}

.modal-content {
  border: 1px solid rgba(99, 166, 253, 0.4);
  background-color: #fff;
  z-index: 3000;
  height: calc(100% - 60px);
  padding: 25px;
  overflow: auto;
}

.main-header-text {
  font-size: 20px;
  color: rgba(99, 166, 253, 0.9);
}

.second-header-text {
  font-size: 12px;
  color: rgba(83, 83, 83, 0.9);
  padding-bottom: 30px;
}

.ref-date-heading {
  font-size: 14px;
  line-height: 17px;
  color: rgba(83, 83, 83, 0.9);
  padding-bottom: 5px;
}

.incidents-heading {
  font-size: 14px;
  color: rgba(83, 83, 83, 0.9);
  padding-bottom: 10px;
  padding-top: 30px;
}

.ref-date-placeholder {
  padding-left: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(99, 166, 253, 0.2);
  border-radius: 15px;
  border: 1px solid rgba(99, 166, 253, 1);
  width: 120px;
  height: 38px;
  font-size: 14px;
  color: rgba(83, 83, 83, 0.8);
}

.ref-date-placeholder[disabled] {
  opacity: 0.5;
  filter: grayscale(1);
}

.arrows-mobile[disabled] {
  filter: grayscale(1);
  opacity: 0.5;
}

.search-dropdown-mobile {
  padding-top: 10px;
}

.dates-mobile {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: space-between;
}

.dates-mobile-child {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 15px;
  color: #535353;
}

.dates-mobile-child[disabled] {
  filter: grayscale(1);
  opacity: 0.5;
}

.date-mobile-selected {
  background-color: rgba(99, 166, 253, 0.2);
  border-radius: 10px;
  border: 1px solid rgba(99, 166, 253, 1);
}

.dates-mobile-child svg {
  margin-right: 10px;
}

/* WE DON'T NEED HOVER ON MOBILE */
/* .dates-mobile-child:hover {
  background-color: rgba(99, 166, 253, 0.2);
  border-radius: 15px;
  border: 1px solid rgba(99, 166, 253, 1);
} */

.gap-mobile {
  padding-right: 10px;
  padding-left: 10px;
}

.arrows-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

.arrow-child-mobile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.arrow-child-mobile:hover svg path {
  fill: white;
}

.arrow-child-mobile:disabled svg path {
  background-color: #535353;
  fill: #535353;
}

.arrow-child-mobile:disabled:hover {
  background-color: white !important;
}

.wrapper-esacredit-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  right: 0px;
  bottom: -15px;
}

.esacredit-mobile {
  width: 74px;
  height: 42px;
  font-size: 9px;
}

.esacredit-font {
  font-size: 10px;
}

.location-select__option {
  padding-left: 50px !important;
  position: relative !important;
}

.location-select__option::before {
  content: "";
  position: absolute;
  background: url("../Media/Images/location-pin.svg") no-repeat;
  left: 20px;
  top: 9px;
  bottom: 0;
  z-index: 100;
  width: 15px;
}

/* remove mobile header for bigger screens */
@media screen and (min-width: 769px) {
  .wrapper-header-mobile {
    display: none;
  }
  .modal-content {
    display: none;
  }
  .wrapper-header-mobile-open {
    display: none;
  }
  .header-modal {
    height: 0px;
  }
}

@media screen and (max-width: 375px) {
  .dates-mobile-child {
    padding: 5px 8px 5px 8px;
  }
}

/* Prevent selection of text */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
