.App {
  background-image: url("../Media/Images/info_background.png");
}
@media screen and (min-width: 640px) {
  .main-content-wrapper.sidebar-open {
    width: calc(100vw - 80px);
    margin-left: 80px;
  }
}

.main-content-wrapper {
  width: 100%;
  transition: all 0.5s ease-in-out;
  max-height: -webkit-fill-available;
  z-index: 4000;
}

.menu-button {
  z-index: 6000;
}

.menu-button {
  height: 80px;
  width: 80px;
  position: relative;
  margin: auto;
  padding: 20px 25px 30px;
  transition: all 0.3s;
  cursor: pointer;
  margin-left: -3px;
  transform: translateY(0);
  transition: transform 150ms ease-in-out;
}

.menu-button.hide-on-scroll {
  transform: translateY(-100%);
}

.menu-button.opened .bar:nth-of-type(1) {
  transform: translateY(7px) rotate(45deg);
}
.menu-button.opened .bar:nth-of-type(2) {
  opacity: 0;
}
.menu-button.opened .bar:nth-of-type(3) {
  transform: translateY(-15px) rotate(-45deg);
}

.bar {
  height: 3px;
  width: 35px;
  display: block;
  margin: 8px auto;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s;
}

.menu-button.blue-logo .bar {
  background-color: #011c40;
}

.menu-button.blue-logo.opened .bar {
  background-color: #fff;
}

.bar:nth-of-type(1) {
  animation: rotateR 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
}
.bar:nth-of-type(2) {
  animation: fade 1.5s cubic-bezier(0.1, 0.8, 0.1, 1);
}
.bar:nth-of-type(3) {
  animation: rotateL 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
}
