.wrapper-sidebar {
  z-index: 5000;
  /* overflow: auto; interferes with popover !!! */
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
}

.wrapper-sidebar.show {
  transform: translateX(0);
}

.icons-position {
  margin-bottom: 25px;
  border-color: rgba(225, 225, 237, 0.3);
}

.nav-links a.active svg path,
.nav-links a:hover svg path {
  fill: #e1e1ed;
}

.login-position {
  min-height: 125px;
}

.login-position.active svg path,
.login-position:hover svg path {
  stroke: #e1e1ed;
}

.help-btn {
  position: absolute;
  bottom: 30px;
  left: 20px;
}

.test:hover circle {
  fill: #3b82f6;
}

@media screen and (max-width: 639px) {
  .logo {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    padding: 10px 20px 30px;
    background: #011c40;
    z-index: 30;
  }
}
