/* Wrapper za cijeli info page */
.wrapper-info-page {
  margin: 0 auto;
  min-height: 650px;
}

/* Wrapper za naslov i pozicija drugog naslova */
.wrapper-heading {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .wrapper-heading {
    text-align: left;
  }
  /* Mozilla fix */
  .card .mobile {
    display: contents;
  }
}

.wrapper-cards {
  max-width: 1000px;
  margin: 0 auto;
}

.text-heading-second {
  position: absolute;
  bottom: -23px;
  left: 0;
  width: 255%;
}

/* Stil za kartice */
.transition-container {
  width: 100%;
  perspective: 750px;
  height: 400px;
  margin: 20px 0;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.transition-container:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

@media screen and (min-width: 1024px) {
  .transition-container {
    width: calc(50% - 40px);
    margin: 20px 20px 0px;
    display: inline-block;
  }
}

.card,
.card-back {
  backface-visibility: hidden;
  transform: rotateX(0deg);
}

.card {
  height: 100%;
  background-color: rgba(242, 242, 242, 0.4);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-back {
  height: 100%;
  background-color: rgba(242, 242, 242, 0.4);
  border-radius: 20px;
  transform: rotateY(180deg);
}

.btn-style {
  border-radius: 50%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-big {
  transform: scale(0.7);
}

.display-mobile {
  display: none;
}

.info-small {
  display: none;
}

@media screen and (max-width: 1025px) {
  .transition-container {
    position: relative;
    height: initial;
  }
  .content {
    position: relative;
  }

  .wrapper-heading {
    margin-top: 20px;
  }

  .text-heading-first {
    font-size: 40px;
    margin-left: -60px;
  }

  .text-heading-second {
    bottom: -16px;
    font-size: 24px;
  }

  .wrapper-cards {
    pointer-events: none;
  }

  .card {
    display: flex;
    align-items: flex-start;
    height: fit-content;
    width: fit-content;
  }

  .info-big svg {
    display: none;
  }

  .info-small {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .display-mobile {
    display: inline;
  }

  .btn-style {
    padding: 16px;
  }

  .mobile {
    padding: 15px 15px 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .display-mobile {
    padding: 20px;
    text-align: justify;
    color: white;
  }

  .text-mobile {
    margin-left: 15px;
    font-size: 24px;
    text-align: center;
  }
}

@media screen and (max-width: 426px) {
  .mobile {
    padding: 15px 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .text-heading-first {
    margin-left: -60px;
  }
}
