.header-slider {
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 40000;
  background-color: #fff;
  border-radius: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.slider-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #022859;
  font-weight: 600;
  border-radius: 10px;
  text-transform: uppercase;
  text-align: center;
}

.slider-datepicker {
  width: 49%;
}

.label-reference-date label {
  top: 6px;
  left: 37px;
}

.label-selected-date label {
  left: initial;
  margin-left: 12px;
  top: 6px;
}

@media screen and (min-width: 480px) {
  .slider-datepicker {
    width: 180px;
  }
}

@media screen and (max-width: 640px) {
  .slider-heading {
    font-size: 12px;
    line-height: 1.2;
  }
  .header-slider {
    top: initial;
    bottom: 10px;
    padding: 0 10px;
  }
  .label-reference-date label {
    left: 22px;
  }
}

@media screen and (max-width: 380px) {
  .slider-heading {
    font-size: 10px;
  }
  .header-slider {
    padding: 0;
    border-radius: 0;
    bottom: 0;
  }
  .label-reference-date label {
    left: 12px;
  }
}
