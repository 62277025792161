.joyride-wrapper {
  z-index: 6000 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.joyride-body {
  background-color: white;
  width: 722px;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.joyride-skip {
  position: absolute;
  right: 12px;
  top: 8px;
}

.joyride-skip:hover svg path {
  fill: white;
}

.title-style {
  font-size: 28px;
  color: #535353;
  padding-bottom: 30px;
  padding-top: 15px;
  line-height: 1.2;
}

.joyride-navigation {
  background-color: #63a6fd;
  border-radius: 10px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 28px;
  color: white;
  border: none;
}

.intro-element-btn {
  width: 240px;
  height: 39px;
  border: none;
  border-radius: 10px;
}

.joyride-logo-gif {
  position: absolute;
  top: 18.5%;
  left: 36.5%;
}

.joyride-logo {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
}

.joyride-body-content {
  color: #707070;
  font-size: 16px;
  line-height: 1.2;
}

.joyride-body-rest .joyride-body-content {
  font-size: 14px;
  line-height: 1.2;
}

.joyride-body-rest .title-style {
  font-size: 20px;
}

.joyride-body .joyride-body-content {
  padding-left: 50px;
  padding-right: 50px;
}

.joyride-logo svg {
  width: 200px;
  height: auto;
}

.info-blue-bg {
  position: relative;
  background-color: #0f2b50;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 311px;
  height: 133px;
  color: white;
  border-radius: 10px 10px 0px 0px;
}

.joyride-body-rest {
  display: flex;
  text-align: justify;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 311px;
  /* min-height: 223px; */
  border-radius: 0px 0px 10px 10px;
}

.intro-rest-btn {
  width: 174px;
  height: 39px;
}

button:focus {
  outline: 0;
}

.joyride-icon img {
  border-radius: 10px 10px 0px 0px;
}
