.dashboard-wrapper {
  height: calc(100% - 68px);
}

@media screen and (min-width: 1024px) {
  .table-container {
    width: 55%;
  }
  .graph-container {
    width: 45%;
  }
}

.dashboard-wrapper.disabled {
  filter: grayscale(1);
  opacity: 0.6;
}

/* SCROLLBAR TABLE */
/* total width */
.table-container::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.table-container::-webkit-scrollbar-track {
  background-color: #fff;
}
.table-container::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.table-container::-webkit-scrollbar-thumb {
  background-color: #d8d8de;
  border-radius: 16px;
  border: 5px solid #fff;
}
.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #cacacf;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.table-container::-webkit-scrollbar-button {
  display: none;
}

.graph-box {
  height: calc(50% - 8px);
}

/* SCROLLBAR GRAPHS */
/* total width */
.graph-box::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.graph-box::-webkit-scrollbar-track {
  background-color: #fff;
}
.graph-box::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.graph-box::-webkit-scrollbar-thumb {
  background-color: #d8d8de;
  border-radius: 16px;
  border: 5px solid #fff;
}
.graph-box::-webkit-scrollbar-thumb:hover {
  background-color: #cacacf;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.graph-box::-webkit-scrollbar-button {
  display: none;
}

label {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #022859;
  left: 15px;
  position: absolute;
  z-index: 100;
  top: -5px;
  background: white;
  padding: 0 4px;
}

@media screen and (min-width: 769px) {
  .location-filter {
    right: 250px;
    width: 220px;
  }
}

@media screen and (min-width: 769px) {
  .react-datepicker-wrapper {
    width: 220px !important;
  }
}

@media screen and (max-width: 767px) {
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    left: -20px !important;
  }
}

.react-datepicker {
  width: 220px !important;
  border-radius: 10px !important;
  border-color: rgba(211, 211, 211, 0.5) !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
  color: #535353 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #535353 !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: rgba(211, 211, 211, 0.5) !important;
}

.react-datepicker__header {
  border-bottom-color: rgba(211, 211, 211, 0.5) !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select),
.react-datepicker__header {
  border-top-right-radius: 10px !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker-wrapper input {
  width: 100%;
  border: 1px solid #63a6fd;
  box-shadow: 0px 2px 4px rgba(182, 215, 254, 0.5);
  border-radius: 15px;
  padding: 8px 20px;
  padding-right: 50px;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #535353 !important;
  cursor: pointer;
}

.react-datepicker-wrapper input::placeholder {
  font-family: Roboto, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: rgba(2, 40, 89, 0.6) important;
}

.react-datepicker__input-container::after {
  content: "";
  position: absolute;
  background: url("../Media/Images/calendar.svg") no-repeat center;
  right: 0;
  top: 0;
  width: 40px;
  height: 39px;
  pointer-events: none;
  cursor: pointer;
}

.react-datepicker__input-container::before {
  content: "";
  position: absolute;
  right: 0;
  top: 10px;
  width: 38px;
  height: 22px;
  border-left: 1px solid hsl(0, 0%, 80%);
  z-index: 100;
  pointer-events: none;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  padding: 5px 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: rgba(99, 166, 253, 0.1) !important;
  color: #535353 !important;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: rgba(99, 166, 253, 0.1) !important;
  color: #535353 !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: rgba(99, 166, 253, 0.1) !important;
  color: #535353 !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: rgba(99, 166, 253, 0.1) !important;
  color: #535353 !important;
}
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: rgba(99, 166, 253, 0.1) !important;
  color: #535353 !important;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  background-color: rgba(99, 166, 253, 0.1) !important;
  color: #535353 !important;
}

.dashboard-locate {
  cursor: pointer;
}

.dashboard-locate:hover svg path {
  stroke: #63a6fd;
}
