.hover-underline-animation {
  background-image: linear-gradient(90deg, #3b82f6, #3b82f6);
  background-size: 0% 3px;
  background-repeat: no-repeat;
  background-position: left bottom;
  text-decoration: none;
  transition: background-size 150ms ease;
}

.hover-underline-animation:hover {
  background-size: 100% 3px;
}

@media screen and (max-width: 768px) {
  .media-button {
    display: none;
  }
}
