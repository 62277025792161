.detections-table div.status:last-child {
  position: relative;
  padding-left: 25px;
}

.detections-table div.detected:last-child {
  color: #19cf7a;
}

.detections-table div.false-positive:last-child {
  color: #ff9548;
}

.detections-table div.confirmed:last-child {
  color: #f13f3f;
}

.detections-table div.status:last-child:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 16px;
  height: 100%;
}

.detections-table div.detected:last-child:before {
  background: url("../Media/Images/detected.svg") no-repeat center;
}

.detections-table div.false-positive:last-child:before {
  background: url("../Media/Images/false-positive.svg") no-repeat center;
}

.detections-table div.confirmed:last-child:before {
  background: url("../Media/Images/confirmed.svg") no-repeat center;
}

.date-cell {
  min-width: 95px;
}
