/* Leaflet Zoom control overrides */
.leaflet-top .leaflet-control {
  margin-top: 45vh;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 0px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.leaflet-touch .leaflet-bar a {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.leaflet-bar a {
  z-index: 200;
  color: #333333;
}

.leaflet-bar a:hover {
  background-color: #63a6fd;
  color: #fff;
}

/* Layer swipe, locate user, current view buttons */
.wrapper {
  top: 45vh;
  height: 300px;
  margin-top: -50px;
}

.wrapper svg {
  display: inline;
}

.parent-slider-btn {
  z-index: 2000;
}

.parent-position-download-btn {
  right: 0%;
  top: 45%;
  z-index: 2000;
}

.slider-btn {
  border-radius: 50%;
  border: none;
}

.slider-btn:hover svg path {
  fill: white;
}

.slider-btn:disabled svg path {
  fill: #bbb;
}

.slider-btn:hover:disabled svg path {
  fill: #bbb;
}

.position-download-btn {
  border-radius: 50%;
  border: none;
  margin-top: 5px;
}

.position-download-btn:hover svg path {
  fill: #fff;
}

/* ESA credits */
.esacredit {
  z-index: 1900;
}

/* SLIDER */
.range {
  position: absolute;
  width: 200px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

/* not in use? */
.slider-container {
  width: 230px;
  height: 55px;
  background-color: #fff;
  display: flex;
  z-index: 12200;
  position: absolute;
}

/* .marker-status-detected:first-child path {
  fill: #faec76;
}

.marker-status-pending:first-child path {
  fill: #ff9548;
}

.marker-status-approved:first-child path {
  fill: #17e383;
} */

.slider-btn-active svg path {
  fill: white;
}

.slider-btn-active {
  background-color: #63a6fd !important;
}
