/* Layer control style */
.base-layer-icon {
  border: 4px solid rgba(1, 18, 40, 0.9);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

/* layer control bottom position */
.leaflet-left .leaflet-control {
  bottom: 65px;
}

.base-layer-icon img {
  border-radius: 6px;
}

.base-layer-icon:hover .base-layer-text,
.base-layer-text:hover {
  color: #535353;
  background-color: #fff;
}

.layer-active .base-layer-icon {
  color: #fff;
  background-color: rgba(127, 212, 165, 0.6);
}

.layer-active .base-layer-text {
  color: #fff;
  background-color: rgba(127, 212, 165, 0.6);
}

.leaflet-left {
  margin-left: 10px;
}

.base-layer-image {
  cursor: pointer;
  border-radius: 10px;
}

.base-layer-groups {
  display: flex;
  flex-wrap: nowrap;
}

.base-layer-text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  border: #fff;
  background-color: #fff;
  border-radius: 0px 0px 6px 6px;
  color: #535353;
}

.layer-control {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px 45px 45px 10px;
}

.layer-control-open {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 108px;
  min-width: 300px;
  background-color: rgba(1, 18, 40, 0.9);
}

.layer-control-open .base-layer-icon {
  border: 4px solid #fff;
  margin: 10px;
}

.layer-control-open .base-layer-icon:hover {
  border: 4px solid #7fd4a5;
}

.layer-control-open .layer-active {
  border: 4px solid #7fd4a5;
}

.layer-control-group-base-layers {
  padding-right: 38px;
}

.layer-control-group-base-layers:before {
  position: absolute;
  padding-left: 250px;
  left: 0px;
  bottom: 14px;
  content: "";
  height: 80px;
  width: 1px;
  border-right: 1px solid rgba(99, 130, 191, 0.2);
}

.base-layer-icon .base-layer-detections .layer-active {
  border-radius: 50%;
}

.base-layer-icon .base-layer-imagery .layer-active {
  border-radius: 50%;
}

.base-layer-image,
.base-layer-detections img {
  border-radius: 50%;
}

.base-layer-image,
.base-layer-detections img {
  border-radius: 50%;
}

/*active detections layer */
.base-layer-icon.base-layer-detections.layer-active {
  border: 4px solid rgba(243, 92, 92, 0.8);
}

.base-layer-icon.base-layer-imagery.layer-active {
  border: 4px solid rgba(243, 92, 92, 0.8);
}

/* active detections layer - TEXT */
.base-layer-text.base-layer-detections {
  border-radius: 40px !important;
  border: 2px solid rgba(243, 92, 92, 0.8);
  background-color: white;
  color: rgba(83, 83, 83, 0.9);
}

.base-layer-text.base-layer-imagery {
  border-radius: 40px !important;
  border: 2px solid rgba(243, 92, 92, 0.8);
  background-color: white;
  color: rgba(83, 83, 83, 0.9);
}

/* hover div image incidents */
.base-layer-icon.base-layer-detections.layer-active:hover {
  border: 4px solid rgba(243, 92, 92, 0.8);
}

.base-layer-icon.base-layer-imagery.layer-active:hover {
  border: 4px solid rgba(243, 92, 92, 0.8);
}

.base-layer-icon.base-layer-detections.layer-inactive:hover {
  border: 4px solid rgba(243, 92, 92, 0.8);
}

.base-layer-icon.base-layer-imagery.layer-inactive:hover {
  border: 4px solid rgba(243, 92, 92, 0.8);
}

.base-layer-detections.layer-inactive .base-layer-text {
  border: 2px solid white;
}

.base-layer-imagery.layer-inactive .base-layer-text {
  border: 2px solid white;
}

.base-layer-detections.layer-inactive:hover .base-layer-text {
  border: 2px solid rgba(243, 92, 92, 0.8);
}

.base-layer-imagery.layer-inactive:hover .base-layer-text {
  border: 2px solid rgba(243, 92, 92, 0.8);
}

.base-layer-icon.base-layer-detections.layer-inactive img {
  opacity: 0.4;
}

.base-layer-icon.base-layer-imagery.layer-inactive img {
  opacity: 0.4;
}

.layer-control-open .section-1 {
  padding-left: 20px;
}

/*border radius div image */
.base-layer-detections {
  border-radius: 50%;
}

.base-layer-imagery {
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .base-layer-icon {
    width: 60px;
    height: 60px;
    border: 3px solid rgba(1, 18, 40, 0.9);
  }
  .base-layer-text.base-layer-detections {
    margin-left: -5px;
    min-width: 65px;
  }

  .base-layer-text.base-layer-imagery {
    margin-left: -5px;
    min-width: 65px;
  }

  .base-layer-icon img {
    border-radius: 6px;
  }

  .layer-control {
    position: fixed;
    justify-content: flex-end;
    border-radius: 45px 10px 10px 45px;
    top: 65px;
    right: 0%;
    margin: 10px;
  }

  .layer-control-open {
    height: 80px;
    min-width: 270px;
    background-color: rgba(1, 18, 40, 0.5);
    flex-direction: row-reverse;
  }

  .layer-control-open .section-0 {
    padding-left: 20px;
  }

  .layer-control-open .section-1 {
    padding-right: 10px;
  }
  .layer-control-group-base-layers:before {
    left: 0px;
    bottom: 10px;
    height: 60px;
    padding-left: 200px;
    border-right: 1px solid #fff;
    opacity: 0.4;
  }

  .layer-control-open .base-layer-icon:hover {
    border: 3px solid #7fd4a5;
  }

  .layer-control-open .base-layer-icon {
    border: 3px solid #fff;
  }

  .layer-control-group-base-layers {
    padding-right: 0px;
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 426px) {
  .layer-control-open .section-1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .layer-control-open .section-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .layer-control-group-base-layers:before {
    padding-left: 160px;
  }
}

@media screen and (max-width: 376px) {
  .layer-control-open {
    max-width: 320px;
  }
  .layer-control-group-base-layers:before {
    padding-left: 150px;
  }
  .layer-control-open .base-layer-icon {
    margin: 8px;
  }
}

@media screen and (max-width: 321px) {
  .layer-control-group-overlay.base-layer-groups.section-1 {
  }
  .layer-control-group-base-layers:before {
    display: none;
  }
  .layer-control-open .base-layer-icon {
    margin: 5px;
  }
}
