.login-wrapper {
  min-height: 600px;
}

.card-login {
  max-width: 468px;
  background-color: rgba(242, 242, 242, 0.4);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  padding: 50px 100px 100px;
}

.card-login input {
  font-size: 12px;
  color: #3f3f46;
  border-radius: 10px;
  padding-left: 15px;
}

form svg {
  margin: 0 auto;
}

.help-text {
  font-size: 14px;
  line-height: 1.2;
  padding-left: 15px;
}

.login-style {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin: 0px 0 15px;
  border-radius: 10px;
  border: 2px solid #63a6fd;
}

.checkbox-login {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
}

.btn-login {
  height: 40px;
  width: 100%;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
}

@media screen and (max-width: 1025px) {
  .card-login {
    max-width: 468px;
  }
}

@media screen and (max-width: 639px) {
  .card-login {
    width: 100%;
    max-width: initial;
    height: 100%;
    border-radius: 0px;
    padding: 10px 20px 20px;
    margin: 0px;
  }
}

.login-popover {
  width: 150px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  position: relative;
  margin-left: 80px;
  margin-top: -125px;
  transform: translateX(-105%);
  transition: transform 0.5s ease-in-out;
}

.login-popover.visible {
  transform: translateX(0);
}

.btn-logout {
  border-radius: 7px;
}

input:focus {
  outline: 0;
}
