/* Loader */
.loader-component {
  position: absolute;
  z-index: 6000;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(1px);
}

/* React joyride height override so that scrollbar doesn't show */
.react-joyride__overlay {
  height: auto !important;
}
