/* Leaflet popup overrides */
.leaflet-popup-content {
  margin: 0px;
  min-width: 300px;
}

.leaflet-container a.leaflet-popup-close-button {
  background-color: #63a6fd;
  border-radius: 10px;
  width: 100px;
  height: 32px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 12px;
  top: auto;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  font: 16px/24px Roboto, sans-serif;
}

.leaflet-container a.leaflet-popup-close-button:after {
  content: "Close";
}

.leaflet-container a.leaflet-popup-close-button span {
  display: none;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  background-color: #3b82f6;
  color: white;
}

/* Main heading */
.popup-wrapper-heading {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  gap: 50px;
  padding: 12px 30px 5px 30px;
  border-bottom: 1px solid rgba(124, 177, 245, 0.3);
}

.popup-right-side {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.popup-left-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.popup-dates-style {
  font-size: 13px;
  flex-wrap: nowrap;
  color: #63a6fd;
}

.marker-icon {
  display: flex;
  justify-content: center;
  margin-top: -18px;
  padding-left: 5px;
  padding-bottom: 5px;
}

.marker-icon svg {
  fill: white;
}

.title-popup {
  display: flex;
  justify-content: center;
  color: #535353;
  font-size: 30px;
  padding-bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.popup-text-wrapper {
  display: flex;
  justify-content: center;
  min-width: 210px;
  align-items: center;
  flex-direction: column;
  margin-bottom: 75px;
}

.popup-text-child {
  display: flex;
  flex-direction: row;
  min-width: inherit;
  min-width: 100%;
  justify-content: space-between;
  padding-bottom: 3px;
  padding-top: 3px;
}

.popup-left-text {
  font-size: 14px;
  color: #3f3f46;
  font-weight: bold;
  padding-left: 50px;
  min-width: 120px;
}

.popup-right-text {
  font-size: 14px;
  color: #3b82f6;
  padding-right: 50px;
  min-width: 120px;
}

.popup-row-color {
  background-color: rgba(99, 166, 253, 0.05);
}
