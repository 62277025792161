.legend-wrapper {
  width: max-content;
  padding: 15px;
  height: max-content;
  z-index: 2900;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(1, 18, 40, 0.9);
  border-radius: 10px;
  bottom: 10px;
  left: 20px;
}

.legend-color {
  width: 25px;
  height: 8px;
}

.color-wrapper {
  display: flex;
  flex-direction: row;
}

.legend-title {
  color: white;
  font-size: 16px;
  line-height: 1.2;
}

.low-high-wrapper {
  display: flex;
  color: white;
  font-size: 12px;
  line-height: 1.2;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 2px;
}

@media screen and (max-width: 768px) {
  .legend-wrapper {
    bottom: initial;
    left: initial;
    right: 10px;
    top: 10px;
    background-color: rgb(15, 43, 80, 0.5);
  }
}

@media screen and (max-width: 426px) {
  .legend-color {
    width: 20px;
  }
}

@media screen and (max-width: 426px) {
  .legend-color {
    width: 17px;
  }
}

@media screen and (max-width: 321px) {
  .legend-color {
    width: 14px;
  }
}
